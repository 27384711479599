import { ENROLLMENT_STATUS } from "../constants";
import { enrollmentStatusType } from "../types";

const renderStatusEnrollment = (statusId: enrollmentStatusType) => {
    switch (statusId) {
        case ENROLLMENT_STATUS.ACTIVE:
            return "primary";
        case ENROLLMENT_STATUS.COMPLETED:
            return "success";
        case ENROLLMENT_STATUS.ERROR:
            return "danger";
        case ENROLLMENT_STATUS.NOT_STARTED:
        case ENROLLMENT_STATUS.CHANGE:
        case ENROLLMENT_STATUS.POSTPONED:
        case ENROLLMENT_STATUS.SPECIALIZATION:
            return "warning";
        case ENROLLMENT_STATUS.RETIRED:
            return "dark";
        default:
            return "secondary"
    }
}

const Utils = {
    renderStatusEnrollment
}

export default Utils;