import React, { useEffect, useState } from "react";
import {
    useDispatch,
    useSelector
} from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    regular,
    solid
} from "@fortawesome/fontawesome-svg-core/import.macro";
import { sprintf } from "sprintf-js";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import CommonTable,
{ Column } from "../../commonTable";
import UnderlineHeading from "../../underlineHeading";
import Constants,
{
    invoiceStatus,
    paymentStatus,
    PERMISSIONS_CODES,
    TYPE_OF_DISCOUNT
} from "../../../constants";
import {
    AppDispatch,
    disableIdleLockSwitch,
    displayNotice,
    enableIdleLockSwitch,
    RootState
} from "../../../globals";

import { styles } from "./style";
import { UserState } from "../../../redux/reducers/sessionReducer";
import HttpManager from "../../../services/HttpManager";
import { getErrorMessage } from "../../../common";
import { useForm } from "react-hook-form";
import { handleInvoicePdf } from "../../../redux/reducers/invoicesReducer";

export interface InvoiceDetailProps extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * The student data to show.
     */
    student: any;
    /**
     * Content to render inside the card.
     */
    invoice: any;
    /**
     * 
     * @function to close Modal
     */
    closeModal: () => void
    /**
     * 
     * @function to close Modal
     */
    showPaymentDetail: () => void
    /**
     * 
     * @function to close Modal
     */
    cancelInvoice: () => void
    /**
     * 
     * @function to close Modal
     */
    proccessPayment: () => void
}

interface InvoiceFormData {
    notes: string;
}

const InvoiceDetailModalComponent: React.FC<InvoiceDetailProps> = ({
    student,
    invoice,
    closeModal,
    showPaymentDetail,
    cancelInvoice,
    proccessPayment
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const http = HttpManager.getInstance();
    const userData: UserState | null = useSelector((state: RootState) => state.rootReducer.sessionState.user);
    const currentCampus: any = useSelector((state: RootState) => state.rootReducer.sessionState.currentCampus);
    const permissions: any[] = useSelector((state: RootState) => state.rootReducer.sessionState.permissions);
    const idleLocked: boolean = useSelector((state: RootState) => state.idleLockSwitch.value);
    const [notes, setNotes] = useState<string>(invoice?.notes);
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors }
    } = useForm<InvoiceFormData>();

    const conceptsColumns: Column[] = [
        {
            id: "",
            header: Constants.Common.concept,
            accessor: "",
            render: (data: any) =>
                <div style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center"
                }}>
                    <label style={{
                        wordBreak: "break-all",
                        whiteSpace: "unset",
                        position: "relative",
                    }}>
                        <strong style={{
                            color: (data.statusId === invoiceStatus.PARTLY_PAID ? "#C32722" : "#000000")
                        }}>
                            {data.PRODUCT_TYPE === "TUITION" ? data.monthNumber : data.name}
                        </strong>
                    </label>
                    <label style={{
                        width: "100%",
                        position: "relative",
                        wordBreak: "break-all",
                        whiteSpace: "unset"
                    }}>
                        {data.PRODUCT_TYPE === "TUITION" ? data.name : `SKU: ${data.sku}`}
                    </label>
                </div>,
            overrideCellStyle: () => ({
                ...styles.cellStyle,
                textAlign: "left",
                whiteSpace: "inherit",
                width: "100px"
            }),
            overrideHeaderStyle: () => ({
                ...styles.subtitle,
                textAlign: "left"
            })
        },
        {
            id: "",
            header: Constants.Common.quantity,
            accessor: "",
            render: (data: any) => data?.quantity ?? 1,
            overrideCellStyle: () => ({
                ...styles.cellStyle,
                textAlign: "center"
            }),
            overrideHeaderStyle: () => ({
                ...styles.subtitle,
                textAlign: "center"
            })
        },
        {
            id: "",
            header: Constants.Common.price,
            accessor: "",
            render: (data: any) => data.price.moneyFormat,
            overrideCellStyle: () => ({
                ...styles.cellStyle,
                textAlign: "right"
            }),
            overrideHeaderStyle: () => ({
                ...styles.subtitle,
                textAlign: "left"
            })
        },
        {
            id: "discount",
            header: Constants.Common.discount,
            accessor: "",
            render: (data: any) =>
                <div>
                    {(+data.discount.typeId === TYPE_OF_DISCOUNT.PERCENTAGE)
                        ?
                        <div style={{ textAlign: "center" }}>
                            {`(${data.discount.percentage}%)`}
                        </div>
                        :
                        <></>
                    }
                    <div style={{ textAlign: "center" }}>
                        {data.discount.moneyFormat}
                    </div>
                </div>
            ,
            overrideCellStyle: () => ({
                ...styles.cellStyle,
                textAlign: "right"
            }),
            overrideHeaderStyle: () => ({
                ...styles.subtitle,
                textAlign: "left",
                width: "100px"
            })
        },
        {
            id: "",
            header: Constants.Common.invoiceAmount,
            accessor: "",
            render: (data: any) => data.subTotal.moneyFormat,
            overrideCellStyle: () => ({
                ...styles.cellStyle,
                textAlign: "right"
            }),
            overrideHeaderStyle: () => ({
                ...styles.subtitle,
                textAlign: "left",
                width: "100px"
            })
        },
        {
            id: "",
            header: Constants.Common.reimbursement,
            accessor: "",
            render: (data: any) => data.refundedAmount.moneyFormat,
            overrideCellStyle: () => ({
                ...styles.cellStyle,
                textAlign: "right",
                display: invoice.statusId === invoiceStatus.CANCELLED ? "table-cell" : "none"
            }),
            overrideHeaderStyle: () => ({
                ...styles.subtitle,
                textAlign: "center",
                width: "50px",
                display: invoice.statusId === invoiceStatus.CANCELLED ? "table-cell" : "none"
            })
        },
        {
            id: "",
            header: Constants.Common.tax,
            accessor: "",
            render: (data: any) => data.taxValue,
            overrideCellStyle: () => ({
                ...styles.cellStyle,
                textAlign: "right"
            }),
            overrideHeaderStyle: () => ({
                ...styles.subtitle,
                textAlign: "center",
                width: "50px"
            })
        }
    ];

    const paymentColumns: Column[] = [
        {
            id: "",
            header: Constants.Common.date,
            accessor: "paymentDate",
            overrideCellStyle: (data: any) => ({
                ...data.statusId === paymentStatus.CANCELLED ? styles.cancelledPayment : styles.cellStyle,
                textAlign: "left"
            }),
            overrideHeaderStyle: () => ({
                ...styles.subtitle,
                textAlign: "left",
                width: "80px"
            })
        },
        {
            id: "",
            header: Constants.Screens.Finances.modalPayments.payment.number,
            accessor: "id",
            overrideCellStyle: (data: any) => ({
                ...data.statusId === paymentStatus.CANCELLED ? styles.cancelledPayment : styles.cellStyle,
                textAlign: "center"
            }),
            overrideHeaderStyle: () => ({
                ...styles.subtitle,
                textAlign: "left",
                width: "80px"
            })
        },
        {
            id: "",
            header: Constants.Common.status,
            accessor: "",
            render: (data: any) =>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}>
                    <div style={{ flex: "1" }}>
                        {data.status}
                    </div>
                    <div style={{ flex: "2" }}>
                        <OverlayTrigger
                            overlay={
                                <Tooltip id="tooltip-activos">
                                    {Constants.Common.paymentDetail}
                                </Tooltip>
                            }>
                            <FontAwesomeIcon icon={solid("chevron-right")} />
                        </OverlayTrigger>
                    </div>
                </div>,
            overrideCellStyle: (data: any) => ({
                ...data.statusId === paymentStatus.CANCELLED ? styles.cancelledPayment : styles.cellStyle,
                textAlign: "right"
            }),
            overrideHeaderStyle: () => ({
                ...styles.subtitle,
                textAlign: "left",
                width: "100px"
            })
        },
        {
            id: "",
            header: Constants.Common.balance,
            accessor: "",
            render: (data: any) => data.amount.moneyFormat,
            overrideCellStyle: (data: any) => ({
                ...data.statusId === paymentStatus.CANCELLED ? styles.cancelledPayment : styles.cellStyle,
                textAlign: "right"
            }),
            overrideHeaderStyle: () => ({
                ...styles.subtitle,
                textAlign: "left",
                width: "70px"
            })
        }
    ];

    const handleDownloadClick = async () => {
        try {
            dispatch(enableIdleLockSwitch());
            await dispatch(handleInvoicePdf({
                sessionToken: userData?.token ?? "",
                campusId: currentCampus.id,
                studentId: student.id,
                invoiceId: invoice.id
            })).unwrap(); // unwrap para manejar correctamente errores en createAsyncThunk;
        } catch (error) {
            onError("Hubo un error al tratar de obtener el documento!");
        }
        finally {
            dispatch(disableIdleLockSwitch());
        }
    }

    const onError = (message: string) => {
        dispatch(displayNotice({
            cornerClose: false,
            message: message,
            heading: <h3 style={{ color: "#FF0000", display: "inline-block" }}>
                Error
            </h3>
        }))
    }

    const onSuccessUpdate = () => {
        reset();
        dispatch(displayNotice({
            cornerClose: false,
            message: "Información procesada con éxito",
            heading: <h3 style={{
                color: "#00FF00", display: "inline-block"
            }}>
                Listo
            </h3>
        }))
    }

    const handleNotes = (e: any) => {
        let value = e.target.value;
        setNotes(value);
        setValue('notes', value);
    }

    const updateInvoice = async (data: InvoiceFormData) => {
        try {
            dispatch(enableIdleLockSwitch());
            const payload = {
                ...data
            };
            const response = await http.invoiceService.updateInvoice(
                userData?.token ?? "",
                currentCampus.id,
                student.id,
                invoice.id,
                payload
            );
            onSuccessUpdate();
        } catch (error) {
            console.error("Error submitting form:", error);
            onError(getErrorMessage(error));
        } finally {
            dispatch(disableIdleLockSwitch());
        }
    }

    useEffect(() => {
        setNotes(invoice?.notes);
        setValue('notes', invoice?.notes);
    }, [invoice]);

    return (
        invoice && student &&
        <React.Fragment>
            {invoice.statusId === invoiceStatus.CANCELLED ?
                <div style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%) rotate(-45deg)",
                    fontSize: "100px",
                    color: "rgba(252, 3, 28, 0.4)",
                    whiteSpace: "nowrap",
                    zIndex: 1,
                    fontFamily: "Arial, sans-serif",
                    fontWeight: "bold",
                    textTransform: "uppercase"
                }}>
                    {Constants.Common.invoiceIsCancel}
                </div> : <React.Fragment />
            }
            <div style={{
                display: "flex",
                alignItems: "stretch"
            }}>
                <h2 style={{ flexGrow: 1 }}>
                    <span style={{
                        ...styles.subtitleContainer,
                        paddingLeft: "10px"
                    }}>
                        {Constants.Common.invoice}&nbsp;
                        {invoice.id}
                    </span>
                </h2>
                <button style={{
                    ...styles.btnCloseModal,
                    flexGrow: 0,
                    zIndex: 2
                }}
                    onClick={closeModal}
                >
                    <FontAwesomeIcon style={{
                        height: "100%",
                        position: "relative",
                        bottom: "1px"
                    }}
                        icon={solid("times")} />
                </button>
            </div>
            <div style={{
                display: "flex",
                alignItems: "stretch",
                flexWrap: "wrap"
            }}>
                <div style={{
                    display: "inline-flex",
                    alignItems: "baseline"
                }}>
                    <label style={{
                        ...styles.smallGrayText,
                        padding: "0px 5px",
                        position: "initial"
                    }}>
                        {Constants.Common.status}
                    </label>
                    <span style={{ ...styles.boldText }}>
                        {invoice.status}
                    </span>
                </div>
                <div style={{
                    display: "inline-flex",
                    alignItems: "baseline"
                }}>
                    <label style={{
                        ...styles.smallGrayText,
                        padding: "0px 5px",
                        position: "initial"
                    }}>
                        {Constants.Common.dueDate}
                    </label>
                    <div>
                        {invoice.invoiceDate}
                    </div>
                </div>
                <div style={{
                    display: "inline-flex",
                    alignItems: "baseline"
                }}>
                    <label style={{
                        ...styles.smallGrayText,
                        padding: "0px 5px",
                        position: "initial"
                    }}>
                        {Constants.Common.due}
                    </label>
                    <div>
                        {invoice.dueDate}
                    </div>
                </div>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "100%"
            }}>
                <UnderlineHeading name={"Alumno"} />
                <div className="row">
                    <div className="col-sm-6">
                        <div >
                            <label style={{
                                ...styles.smallGrayText,
                                position: "initial",
                                textAlign: "left",
                                marginLeft: "0px"
                            }}>
                                {Constants.Common.names}
                            </label>
                            <div style={{
                                borderBottom: "2px solid #8F91DA"
                            }}>
                                {student.firstName}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div>
                            <label style={{
                                ...styles.smallGrayText,
                                position: "initial",
                                textAlign: "left",
                                marginLeft: "0px"
                            }}>
                                {Constants.Common.lastNames}
                            </label>
                            <div style={{ borderBottom: "2px solid #8F91DA" }}>
                                {student.lastName}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                invoice && invoice?.invoiceItems.length > 0
                    ?
                    <div style={{ width: "100%" }}>
                        <CommonTable
                            className="not-hover"
                            columns={conceptsColumns}
                            data={invoice.invoiceItems}
                        />
                    </div>
                    : <></>
            }
            <div style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginTop: "20px"
            }}>
                <div style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center"
                }}>
                    <label style={{
                        ...styles.smallGrayText,
                        paddingRight: "15px",
                        position: "relative"
                    }}>
                        {Constants.Common.totalText}
                    </label>
                    <h3 style={{ ...styles.subtitleContainer }}>
                        {invoice.currencyCode}&nbsp;
                        {sprintf("%.02f", (invoice.total))}
                    </h3>
                </div>
            </div>
            {
                (invoice.statusId !== invoiceStatus.FULLY_PAID
                    && invoice.statusId !== invoiceStatus.CANCELLED) ?
                    <form onSubmit={handleSubmit(updateInvoice)}
                        className="form">
                        <div style={{
                            marginTop: "10px",
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-end",
                            alignItems: "center"
                        }}>
                            <div style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center"
                            }}>
                                <button style={{ width: "50%" }}
                                    disabled={idleLocked}
                                    className="btn btn-outline-danger rounded-pill"
                                    type="button"
                                    onClick={() => proccessPayment()}
                                >
                                    {Constants.Screens.Finances.modalPayments.emitPayment}
                                </button>
                            </div>
                            <div style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "flex-start"
                            }}>
                                <div style={{
                                    paddingRight: "10px",
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                    gap: "10px"
                                }}>
                                    <label htmlFor="notes"
                                        style={{
                                            ...styles.smallGrayText,
                                            position: "relative"
                                        }}>
                                        {Constants.Common.notes}
                                    </label>
                                    <textarea id="notes"
                                        {
                                        ...register('notes',
                                            {
                                                required: 'El campo notas es requerido.',
                                                minLength: {
                                                    value: 10,
                                                    message: 'La nota debe tener al menos 10 caracteres'
                                                }
                                            }
                                        )}
                                        value={notes}
                                        name="notes"
                                        placeholder={Constants.Common.notes}
                                        style={{
                                            resize: "vertical",
                                            width: "100%",
                                            border: "1px solid #7678BF",
                                            borderRadius: "5px",
                                            minHeight: "100px",
                                            padding: "5px"
                                        }}
                                        onChange={(e: any) => handleNotes(e)}
                                    />
                                    {errors.notes &&
                                        <p style={{
                                            ...styles.errorMessage
                                        }}>
                                            {errors.notes.message}
                                        </p>
                                    }
                                </div>
                            </div>
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={idleLocked}
                                style={{
                                    width: "100%",
                                    marginTop: "25px"
                                }}>
                                {Constants.Common.saveChanges}
                            </button>
                        </div>
                    </form>
                    : ""
            }
            <div className="row"
                style={{
                    marginTop: "10px"
                }}>
                {invoice.statusId !== invoiceStatus.PARTLY_PAID ?
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        marginBottom: "20px",
                        borderBottom: "2px solid #ccc"
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            width: "100%"
                        }}>
                            <label style={{
                                ...styles.smallGrayText,
                                position: "initial",
                            }}>
                                {Constants.Common.notes}
                            </label>
                            <p style={{
                                padding: "5px",
                                margin: "0",
                                wordBreak: "break-word"
                            }}>
                                {invoice.notes}
                            </p>
                        </div>
                    </div>
                    : <></>
                }
                {
                    (invoice.payments === null || invoice.payments.length < 1) ? ""
                        : <React.Fragment>
                            <UnderlineHeading
                                name={Constants.Screens.Finances.modalInvoiceDetail.paymentsReceive} />
                            <CommonTable
                                columns={paymentColumns}
                                data={invoice.payments}
                                onRowClick={showPaymentDetail}
                            />
                        </React.Fragment>
                }
                <div style={{
                    padding: "30px"
                }}
                >
                    <div style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        marginTop: "20px"
                    }}>
                        <div style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center"
                        }}>
                            <label style={{
                                ...styles.smallGrayText,
                                paddingRight: "10px",
                                position: "initial"
                            }}>
                                {Constants.Screens.Finances.modalPayments.approvedTotal}
                            </label>
                            <h3 style={{
                                ...styles.subtitleContainer

                            }}>
                                {invoice.currencyCode}&nbsp;
                                {sprintf("%.02f", +invoice.paidAmount)}

                            </h3>
                        </div>
                    </div>
                </div>
                <div style={{
                    textAlign: "right",
                    paddingTop: "25px",
                    marginBottom: "20px"
                }}>
                    <button
                        type="button"
                        className="btn btnOutlinePurple rounded-pill"
                        disabled={idleLocked}
                        onClick={() => handleDownloadClick()}
                        style={{
                            width: "50%"
                        }}>
                        <FontAwesomeIcon icon={regular("circle-down")}
                            style={{ paddingRight: "10px" }} />
                        {Constants.Screens.Finances.modalInvoiceDetail.btnDownload}
                    </button>
                </div>
                {permissions.includes(PERMISSIONS_CODES.INVOICE_PERMISSIONS.DELETE) ?
                    <div
                        style={{
                            paddingRight: "10px",
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            gap: "10px"
                        }}>
                        {!invoice.isCancelable && !invoice.isGreaterThan90Days && invoice.statusId !== invoiceStatus.CANCELLED ?
                            <p style={{
                                ...styles.errorMessage
                            }}>
                                {Constants.Common.invoiceIsNoCancelable}
                            </p> : <React.Fragment />
                        }
                        <button
                            className="btn btn-warning"
                            type="button"
                            disabled={idleLocked || !invoice.isCancelable}
                            onClick={cancelInvoice}
                        >
                            {Constants.Common.cancel}
                        </button>
                        {invoice.isGreaterThan90Days && invoice.statusId !== invoiceStatus.CANCELLED ?
                            <p style={{
                                ...styles.errorMessage
                            }}>
                                {Constants.Common.isGreaterThan90Days}
                            </p> : <React.Fragment />
                        }
                    </div>
                    : <div
                        style={{
                            paddingRight: "10px",
                            display: "flex",
                            flexDirection: "column",
                            alignContent: "center",
                            width: "100%",
                            gap: "10px"
                        }}>
                        <p style={{
                            ...styles.errorMessage,
                            textAlign: "center"
                        }}>
                            {Constants.permissions.invoices.cancel}
                        </p>
                    </div>
                }
            </div>
        </React.Fragment >
    );

}

export default InvoiceDetailModalComponent;