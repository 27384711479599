import React, {
    useEffect,
    useState
} from "react";

import { useLocation, useNavigate } from "react-router-dom";
import {
    useDispatch,
    useSelector
} from "react-redux";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sprintf } from "sprintf-js";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import Constants,
{
    FILTER_TYPES,
    FINANCES_MODALS,
    invoiceStatus,
    OPERATORS
} from "../../../../constants";
import {
    RootState,
    enableIdleLockSwitch,
    disableIdleLockSwitch,
    displayNotice,
    AppDispatch
} from "../../../../globals";
import HttpManager from "../../../../services/HttpManager";
import {
    handleSelectedInvoice,
    setIsCancelPayment,
    setIsNewInvoice,
    setIsPartialPayment,
    setModalContent,
    setRefundItems,
    setSelectedInvoice,
    setSelectedPayment,
    setShowModal,
    setStudentData
} from "../../../../redux/reducers/invoicesReducer";
import { setPermissions, UserState } from "../../../../redux/reducers/sessionReducer";
import { setPaymentMethods } from "../../../../redux/reducers/catalogReducer";
import { handleSelectedPayment } from "../../../../redux/reducers/paymentsReducer";
import { Column } from "../../../../components/commonTable";
import InvoiceView from "./invoiceView";
import TruncateTextComponent from "../../../../components/truncateText";

const InvoiceController = (props: any, route: any) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const location = useLocation();
    const pagesSize: number[] = [10, 20, 50, 100];
    const http = HttpManager.getInstance();
    const idleLocked: boolean = useSelector((state: RootState) => state.idleLockSwitch.value);
    const userData: UserState | null = useSelector((state: RootState) => state.rootReducer.sessionState.user);
    const currentRole: any = useSelector((state: RootState) => state.rootReducer.sessionState.currentRole);
    const currentCampus: any = useSelector((state: RootState) => state.rootReducer.sessionState.currentCampus);
    const studentData: any = useSelector((state: RootState) => state.rootReducer.invoices.studentData);
    const selectedInvoice: any = useSelector((state: RootState) => state.rootReducer.invoices.selectedInvoice);
    const selectedPayment: any = useSelector((state: RootState) => state.rootReducer.invoices.selectedPayment);
    const isPartialPayment: boolean = useSelector((state: RootState) => state.rootReducer.invoices.isPartialPayment);
    const isNewInvoice: boolean = useSelector((state: RootState) => state.rootReducer.invoices.isNewInvoice);
    const invoiceStatuses: [] = useSelector((state: RootState) => state.rootReducer.catalog.invoiceStatuses);
    const [invoiceIdQueryParam, setInvoiceIdQueryParam] = useState<number>(0);
    const [dateRange, setDateRange] = useState<{ start: Date | null; end: Date | null }>({
        start: null,
        end: null,
    });
    const [total, setTotal] = useState<{ option: string; quantity: number }>({
        option: "",
        quantity: 0,
    });
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [invoices, setInvoices] = useState<any>(null);
    const [pages, setPages] = useState<any>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = useState<number>(pagesSize[0]);
    const [itemCount, setItemCount] = useState<any>(null);
    const [searchValue, setSearchValue] = useState<string>("");
    const [filters, setFilters] = useState<{ [key: string]: any }>({});

    const onChangeSearch = (newSearchValue: string) => {
        setSearchValue(newSearchValue);
    }

    const onClickSearch = () => {
        setFilters({ ...filters, search: searchValue });
    }

    const onClearSearch = () => {
        setSearchValue("");
        setFilters({});
    }

    const onFilterChange = (columnId: string, value: any) => {
        setFilters((prevFilters) => {
            const updatedFilters = { ...prevFilters };

            // Validate date range
            if (columnId === "invoiceDate") {
                const { start, end } = value || {};
                if (!start || !end) {
                    return updatedFilters;
                }
                if (start > end) {
                    return updatedFilters;
                }
            }
            if (columnId === "total") {
                const { option, quantity } = value || {};
                if (!option || quantity <= 0) {
                    return updatedFilters;
                }
            }
            if (Array.isArray(value) && value.length === 0) {
                delete updatedFilters[columnId];
            } else {
                updatedFilters[columnId] = value;
            }

            return updatedFilters;
        });
    }

    const invoiceColumns: Column[] = [
        {
            id: "id",
            header: Constants.Common.id,
            accessor: "id",
            overrideHeaderStyle: () => ({
                textAlign: "left",
                width: "50px",
                color: "black",
                borderBottom: "1px solid black"
            }),
            // filterType: FILTER_TYPES.INPUT
        },
        {
            id: "correlative",
            header: Constants.Common.correlative,
            accessor: "studentIdentityCode",
            // filterType: FILTER_TYPES.INPUT,
            overrideCellStyle: () => ({
                textAlign: "left",
                whiteSpace: "inherit",
                width: "200px"
            }),
            overrideHeaderStyle: () => ({
                textAlign: "left",
                color: "black",
                borderBottom: "1px solid black"
            })
        },
        {
            id: "firstName",
            header: Constants.Common.names,
            accessor: "studentFirstname",
            // filterType: FILTER_TYPES.INPUT,
            overrideCellStyle: () => ({
                textAlign: "left",
                whiteSpace: "inherit",
                width: "200px"
            }),
            overrideHeaderStyle: () => ({
                textAlign: "left",
                color: "black",
                borderBottom: "1px solid black"
            })
        },
        {
            id: "lastName",
            header: Constants.Common.lastNames,
            accessor: "studentLastname",
            // filterType: FILTER_TYPES.INPUT,
            overrideCellStyle: () => ({
                textAlign: "left",
                whiteSpace: "inherit",
                width: "200px"
            }),
            overrideHeaderStyle: () => ({
                textAlign: "left",
                color: "black",
                borderBottom: "1px solid black"
            })
        },
        {
            id: "invoiceDate",
            header: Constants.Common.date,
            accessor: "invoiceDate",
            // filterType: FILTER_TYPES.DATE_RANGE,
            // dateRangeState: {
            //     start: dateRange.start,
            //     end: dateRange.end,
            //     setStart: (date: Date | null) => setDateRange((prev) => ({ ...prev, start: date })),
            //     setEnd: (date: Date | null) => setDateRange((prev) => ({ ...prev, end: date })),
            // },
            overrideHeaderStyle: () => ({
                textAlign: "left",
                color: "black",
                borderBottom: "1px solid black"
            })
        },
        {
            id: "total",
            header: Constants.Common.totalAmount,
            accessor: "",
            render: (invoice) => `${invoice.currencyCode}${sprintf("%.02f", invoice.total)}`,
            // filterType: FILTER_TYPES.AMOUNT_RANGE, // Agrega el tipo de filtro
            // quantityRangeState: {
            //     option: total.option,
            //     setOption: (option: string) => setTotal((prev) => ({ ...prev, option })),
            //     quantity: total.quantity,
            //     setQuantity: (quantity: number) => setTotal((prev) => ({ ...prev, quantity })),
            // },
            // filterOptions: OPERATORS,
            overrideHeaderStyle: () => ({
                textAlign: "left",
                width: "200px",
                color: "black",
                borderBottom: "1px solid black"
            })
        },
        {
            id: "paidAmount",
            header: Constants.Common.paidAmount,
            accessor: "",
            render: (invoice) => `${invoice.currencyCode}${sprintf("%.02f", invoice.paidAmount)}`,
            overrideHeaderStyle: () => ({
                textAlign: "left",
                color: "black",
                borderBottom: "1px solid black"
            })
        },
        {
            id: "pendingAmount",
            header: Constants.Common.pendingAmount,
            accessor: "",
            render: (invoice) => `${invoice.currencyCode}${sprintf("%.02f", invoice.pendingAmount)}`,
            overrideHeaderStyle: () => ({
                textAlign: "left",
                color: "black",
                borderBottom: "1px solid black"
            })
        },
        {
            id: "status",
            header: Constants.Common.status,
            accessor: "",
            render: (data: any) => <span
                style={{
                    color: data.statusId === invoiceStatus.CANCELLED
                        ? Constants.Styles.text.colors.red
                        : Constants.Styles.text.colors.blackPrimary,
                    font: Constants.Styles.text.fonts.robotoBold16,
                    marginBottom: 0
                }}>
                {data.status}
            </span>,
            // filterType: FILTER_TYPES.MULTISELECT,
            // filterOptions: invoiceStatuses,
            overrideHeaderStyle: () => ({
                textAlign: "left",
                color: "black",
                borderBottom: "1px solid black"
            })
        },
        {
            id: "notes",
            header: Constants.Common.notes,
            accessor: "",
            // filterType: FILTER_TYPES.INPUT,
            render: (invoice: any) => (
                <TruncateTextComponent
                    value={invoice.notes}
                    maxLength={10}
                    maxWidth={"100%"}
                />
            ),
            overrideCellStyle: () => ({
                textAlign: "center",
                whiteSpace: "inherit",
                width: "100px"
            }),
            overrideHeaderStyle: () => ({
                width: "100px",
                textAlign: "left",
                color: "black",
                borderBottom: "1px solid black"
            })
        },
        {
            id: "selectInvoice",
            header: "",
            accessor: "",
            render: () => <React.Fragment></React.Fragment>,
            overrideCellStyle: () => ({
                textAlign: "right",
                borderBottom: 0
            }),
            overrideHeaderStyle: () => ({
                textAlign: "left",
                color: "black"
            })
        }
    ];

    const invoiceColumnsWithLogic = invoiceColumns.map((column: any) => {
        if (column.id === "selectInvoice" && column.render) {
            return {
                ...column,
                render: (invoice: any) => (
                    <OverlayTrigger overlay={
                        <Tooltip>
                            {Constants.Common.invoiceDetail}
                        </Tooltip>
                    }>
                        <button
                            className="btn btn-default"
                            type="button"
                            disabled={idleLocked}
                            onClick={() => getInvoiceById(invoice.id)}
                        >
                            <FontAwesomeIcon icon={solid("eye")} flip="horizontal" />
                        </button>
                    </OverlayTrigger>
                ),
            };
        }
        return column;
    });

    const handlePageSizeChange = (newPageSize: number) => {
        setItemsPerPage(newPageSize);
    }

    const resetStateData = () => {
        dispatch(setRefundItems(new Set()));
        dispatch(setIsPartialPayment(false));
    }

    const onError = (message: string) => {
        dispatch(displayNotice({
            cornerClose: false,
            message: message,
            heading: <h3 style={{
                color: "#FF0000",
                display: "inline-block"
            }}>
                Error
            </h3>
        }));
    }

    const setSelectedStudent = (data: any) => {
        const studentData = {
            id: data.studentId,
            email: data.studentEmail,
            userId: data.id,
            firstName: data.studentFirstname,
            lastName: data.studentLastname,
            identityCode: data.identityCode,
            identificationCode: data.identificationCode
        }
        dispatch(setStudentData(studentData));
    }

    const fetchRoleData = async () => {
        try {
            const response = await http.roleService.getRoleData(currentRole.id);
            dispatch(setPermissions(response.data.permissions));
            return response.data;
        } catch (err: any) {
            console.error(err);
        }
    }

    const getPaymentMethods = async () => {
        try {
            const response = await http.paymentMethodService
                .getPaymentMethodsByCountry(currentCampus.countryId);
            const paymentMethods = response.data;
            return paymentMethods;
        } catch (err: any) {
            console.error(err);
        }
    }

    const getInvoicesByCampusId = async () => {
        try {
            const response = await http.invoiceService.getInvoiceByCampusId(
                userData?.token ?? "",
                currentCampus.id,
                itemsPerPage,
                filters
            );
            const invoices = response.data;
            return invoices;
        } catch (err: any) {
            console.error(err);
            return [];
        }
    }

    const getPaginatedInvoices = async (url: string) => {
        dispatch(enableIdleLockSwitch())
        try {
            const response = await http.invoiceService.getPaginatedInvoices(
                userData?.token ?? "", url);
            const invoices = response.data;
            updatePaginationInfo(invoices);
            setInvoices(invoices.data);
            return invoices;
        } catch (err: any) {
            console.error(err);
        } finally {
            setIsLoaded(true);
            dispatch(disableIdleLockSwitch());
        }
    }

    const updatePaginationInfo = (pagination: any) => {
        const updatedPages = pagination.links.map((page: any) => ({
            ...page,
            onClick: () => {
                if (page.url) {
                    getPaginatedInvoices(page.url);
                }
            }
        }));
        setItemCount({
            start: pagination.from,
            end: pagination.to,
            total: pagination.total
        });
        setPages(updatedPages);
        setCurrentPage(pagination.current_page);
    }

    const showPaymentDetail = async (rowData: any) => {
        try {
            dispatch(enableIdleLockSwitch());
            const response = await dispatch(handleSelectedPayment({
                sessionToken: userData?.token ?? "",
                campusId: currentCampus.id,
                paymentId: rowData.id
            })).unwrap();
            dispatch(setModalContent(FINANCES_MODALS.PAYMENT_DETAIL));

            const payment = {
                ...response.data,
                account: response.data.recipientAccount,
                amount: response.data.amount.value,
                files: response.data.documents
            };
            dispatch(setSelectedPayment(payment));
        } catch (error) {
            onError("Hubo un error al tratar de obtener el documento!");
        }
        finally {
            dispatch(disableIdleLockSwitch());
        }
    }

    const cancelInvoice = () => {
        dispatch(setModalContent(FINANCES_MODALS.CANCEL_INVOICE));
    }

    const proccessPayment = () => {
        dispatch(setIsPartialPayment(true));
        dispatch(setIsNewInvoice(false));
        dispatch(setModalContent(FINANCES_MODALS.EMIT_PAYMENT));
    }

    const closeModalInvoiceDetail = () => {
        dispatch(setIsPartialPayment(false));
        dispatch(setShowModal(false));
        dispatch(setSelectedInvoice(null));
    }

    const handleCancelPayment = () => {
        setIsCancelPayment(true);
        dispatch(setModalContent(FINANCES_MODALS.CANCEL_PAYMENT))
    }

    const paymentDetailCloseModal = () => {
        dispatch(setModalContent(FINANCES_MODALS.INVOICE))
    }

    const showSuccessMessage = () => {
        dispatch(displayNotice({
            cornerClose: false,
            message: "Información procesada con éxito",
            heading: <h3 style={{
                color: "#00FF00", display: "inline-block"
            }}>
                Listo
            </h3>
        }));
    }

    const cancelPaymentCloseModal = () => {
        setIsCancelPayment(false);
        dispatch(setModalContent(FINANCES_MODALS.PAYMENT_DETAIL));
    }

    const onSuccessCancelPayment = () => {
        fetchData();
        getInvoiceById(selectedInvoice.id);
        dispatch(setShowModal(true));
        showPaymentDetail(selectedPayment);
        showSuccessMessage();
    }

    const cancelEmitPayment = () => {
        if (isPartialPayment && !isNewInvoice) {
            dispatch(setModalContent(FINANCES_MODALS.INVOICE));
        } else {
            dispatch(setModalContent(FINANCES_MODALS.CHECKOUT));
        }
    }

    const onSuccessEmitPayment = () => {
        fetchData();
        getInvoiceById(selectedInvoice.id);
        dispatch(setShowModal(true));
        dispatch(displayNotice({
            cornerClose: false,
            message: "Información procesada con éxito",
            heading: <h3 style={{
                color: "#00FF00", display: "inline-block"
            }}>
                Listo
            </h3>
        }));
    }

    const closeModalEmitPayment = () => {
        dispatch(setShowModal(false));
    }

    const cancelInvoiceCloseModal = () => {
        dispatch(setModalContent(FINANCES_MODALS.INVOICE));
    }

    const onSuccessCancelInvoice = () => {
        fetchData();
        dispatch(setShowModal(false));
        dispatch(displayNotice({
            cornerClose: false,
            message: "Información procesada con éxito",
            heading: <h3 style={{
                color: "#00FF00", display: "inline-block"
            }}>
                Listo
            </h3>
        }))
    }

    const generateInvoiceCloseModal = () => {
        dispatch(setModalContent(FINANCES_MODALS.CHECKOUT));
    }

    const onSuccessGeneratePaidInvoice = () => {
        fetchData();
        dispatch(setShowModal(false));
        showSuccessMessage();
    }

    const fetchDataByQueryParams = () => {
        const query = new URLSearchParams(location.search);
        const invoiceIdQP = Number.parseInt(query.get('invoiceId') ?? "0");
        setInvoiceIdQueryParam(invoiceIdQP);
        console.log({ location, invoiceIdQP });
    }

    const fetchData = async () => {
        resetStateData();
        const messageError: string = "No se pudo extraer la información financiera del alumno seleccionado"
        dispatch(enableIdleLockSwitch());
        if (currentCampus && userData) {
            try {
                const [paymentMethods, invoices] = await Promise.all([
                    getPaymentMethods(),
                    getInvoicesByCampusId()
                ]);
                setInvoices(invoices.data);
                updatePaginationInfo(invoices);
                dispatch(disableIdleLockSwitch());
                dispatch(setPaymentMethods(paymentMethods));
                setIsLoaded(true);
            }
            catch (error: any) {
                dispatch(disableIdleLockSwitch());
                if (error.status === 404) {
                    navigate("/students");
                }
                else {
                    console.log(error);
                    onError(messageError);
                }
            }
        }
    }

    const getInvoiceById = async (invoiceId: number) => {
        try {
            dispatch(enableIdleLockSwitch());
            let response = await dispatch(handleSelectedInvoice({
                sessionToken: userData?.token ?? "",
                campusId: currentCampus.id,
                studentId: studentData.id,
                invoiceId
            })).unwrap();
            let invoice = response.data;
            setSelectedStudent(invoice);
            dispatch(setSelectedInvoice(invoice));
            dispatch(setIsNewInvoice(false));
            dispatch(disableIdleLockSwitch());
        } catch (error) {
            onError("Hubo un error al tratar de obtener el documento!");
        }
        finally {
            dispatch(disableIdleLockSwitch());
        }
    }

    useEffect(() => {
        if (selectedInvoice && Object.keys(selectedInvoice).length > 0) {
            dispatch(setShowModal(true));
            dispatch(setModalContent(FINANCES_MODALS.INVOICE));
        }
    }, [selectedInvoice]);

    useEffect(() => {
        if (userData && userData.token) {
            if (currentCampus === null) {
                onError(
                    "No hay planteles registrados o asignados al rol en uso por el usuario, " +
                    "por lo que el acceso a este módulo permanecerá deshabilitado."
                )
            }
            else {
                fetchData();
            }
        }
    }, []);

    useEffect(() => {
        fetchRoleData();
    }, [currentRole]);

    useEffect(() => {
        if (isLoaded) {
            fetchData();
        }
    }, [isLoaded]);

    useEffect(() => {
        fetchData();
    }, [itemsPerPage, currentCampus]);

    useEffect(() => {
        if (searchValue === "") {
            setCurrentPage(1);
            fetchData();
        }
    }, [searchValue]);

    useEffect(() => {
        fetchData();
    }, [filters]);

    return !isLoaded || currentCampus === null || userData === null
        ? <React.Fragment></React.Fragment>
        : <InvoiceView
            invoicesProps={{
                invoices,
                columns: invoiceColumnsWithLogic,
                filters,
                onFilterChange
            }}
            paginationProps={{
                pages,
                pagesSize,
                itemCount,
                handlePageSizeChange
            }}
            searchProps={{
                search: searchValue,
                onChangeSearch,
                onClickSearch,
                onClearSearch
            }}
            invoiceDetailProps={{
                cancelInvoice,
                showPaymentDetail,
                proccessPayment,
                closeModal: closeModalInvoiceDetail
            }}
            emitPaymentProps={{
                isNewInvoice,
                isPartialPayment,
                closeModal: closeModalEmitPayment,
                cancelEmitPayment,
                onSuccess: onSuccessEmitPayment
            }}
            paymentDetailProps={{
                handleCancelPayment,
                closeModal: paymentDetailCloseModal
            }}
            cancelPaymentProps={{
                closeModal: cancelPaymentCloseModal,
                onSuccess: onSuccessCancelPayment
            }}
            cancelInvoiceProps={{
                closeModal: cancelInvoiceCloseModal,
                onSuccess: onSuccessCancelInvoice
            }}
            generateInvoiceProps={{
                onSuccess: onSuccessGeneratePaidInvoice,
                closeModal: generateInvoiceCloseModal
            }}
        />
}

export default InvoiceController;