import React from "react";
import { default as SelectComponent } from "react-select";

export interface AmountRangeProps extends React.HTMLAttributes<HTMLDivElement> {
    selectedOption: any; // Este ahora se ajustará a un solo valor en lugar de un array
    onOptionChange: (value: string) => void; // Cambia a un solo valor
    quantity: number;
    onQuantityChange: (value: number) => void;
    filterOptions?: { id: string; label: string; value: string }[];
}

const AmountRangeComponent: React.FC<AmountRangeProps> = ({
    selectedOption,
    onOptionChange,
    quantity,
    onQuantityChange,
    filterOptions
}) => {

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newQuantity = Number(e.target.value);
        onQuantityChange(newQuantity); // Actualiza la cantidad

        // Si hay opciones seleccionadas, actualiza el filtro
        if (selectedOption.length > 0 && newQuantity > 0) {
            onOptionChange(selectedOption);
        }
    };

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px"
        }}>
            <SelectComponent
                id="amount-range-select"
                options={filterOptions}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                onChange={(selectedOption) => {
                    const value = selectedOption ? selectedOption.value : "";
                    onOptionChange(value);
                }}
                styles={{
                    menu: (provided) => ({
                        ...provided,
                        width: 'auto',
                        minWidth: '150px'
                    })
                }}
                placeholder={`Select option`}
                value={filterOptions?.find(option => option.value === selectedOption) || null}
            />
            <input
                type="number"
                value={quantity || 0}
                onChange={handleInputChange}
                placeholder="Enter quantity"
                disabled={selectedOption.length === 0}
            />
        </div>
    );
};

export default AmountRangeComponent;